import { CustomerFileCategory } from '../graphql/generated';

export const PRE_QUALIFICATION_DATA = 'PRE_QUALIFICATION_DATA';
export const REQUITY_HOMES_CONTACT_NUMBER = '1-855-952-6492';
export interface FileWithCategory {
  file: File;
  category: CustomerFileCategory;
}

export const HAS_SEEN_ALL_PAGES = 'HAS_SEEN_ALL_PAGES';

export const MAXIMUM_MONTHLY_DEBT_PAYMENT = 5000;

export const REFERRAL_CODE = '_referralCode';

export const COOKIE_UTM_KEY = '_cookie_utm_key';

export const DEFAULT_MEETING_LINK = 'https://meetings.hubspot.com/kin-dutta';
